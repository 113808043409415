'use strict';

import * as React from 'react'
import { useTranslation } from 'react-i18next';
import {AppMeta} from './appMeta'

export function AppEntry(props: {meta: AppMeta}): React.ReactElement {

  const [hovered, setHovered] = React.useState(false);
  const toggleHover = () => setHovered(!hovered);

  const { t } = useTranslation();

  return (
    <div className="col"
         onMouseEnter={toggleHover}
         onMouseLeave={toggleHover}>
      <a href={props.meta.address} className="text-reset text-decoration-none" target="_blank" rel="noreferrer">
        <div className={"card " + (hovered ? 'bg-secondary text-light' : 'bg-light text-dark')}>
          <div className="card-header" style={{height: "60pt"}}>
            <img src={props.meta.icon} className="card-img-top" style={{height: "100%", width: "auto"}} alt={props.meta.name} />
          </div>
          <ul className="list-group list-group-flush">
            <li className="list-group-item">
              <h4>{t(props.meta.name)}</h4>
            </li>
            <li className="list-group-item">
              <span className={"card-subtitle mb-2 badge " + (props.meta.supportSSO ? 'bg-success' : 'bg-danger')}>
                {t('supports_sso')}
              </span>
            </li>
            <li className="list-group-item">
              <p className="card-text">{t(props.meta.name + '_description')}</p>
            </li>
          </ul>
        </div>
      </a>
    </div>
  )
}
