'use strict';

import * as React from 'react'
import { AppList } from './appList';
import { useTranslation } from 'react-i18next';
import i18n from './i18n/config';

export function App(): React.ReactElement {
  const { t } = useTranslation();

  const changeLanguage = (event: React.ChangeEvent) => {
    i18n.changeLanguage((event.target as HTMLSelectElement).value);
  }

  return (
    <div className="container">
      <div className="row justify-content-end py-4">
        <div className="col-4">
          <select className="form-select" aria-label="Default select example" onChange={changeLanguage}>
            <option value="en" selected>English</option>
            <option value="cs">Čeština</option>
          </select>
        </div>
      </div>

      <div className="pt-5">
        <h1 className="h1 fw-bold">{t('headline')}</h1>
        <p className="h4 my-4" style={{whiteSpace: "pre-line"}}>
          {t('subtitle')}
        </p>
        <div className="d-flex flex-row">
          <a href="https://liberapay.com/ycdmdj/donate" target="_blank" rel="noreferrer" className="btn btn-warning p-2 m-2" role="button"><img height="22pt" className="d-inline-block" alt="Donate using Liberapay" src="liberapay_logo_white-on-yellow.svg" /> {t('donate')}</a>
          <a href="privacy.html" target="_blank" rel="noreferrer" className="btn btn-success p-2 m-2" role="button">🔑 {t('privacy_policy')}</a>
          <a href="tac.html" target="_blank" rel="noreferrer" className="btn btn-danger p-2 m-2" role="button">❗ {t('terms_and_conditions')}</a>
        </div>
        <p className="h5 my-4" style={{whiteSpace: "pre-line"}}>
          {t('headline_description')}
        </p>
        <div className='d-flex flex-row'>
          <a href="https://social.filik.eu/@status" target="_blank" rel="noreferrer" className="btn btn-primary p-2 m-2" role="button">🔔 {t('news_feed')}</a>
          <a href="https://sso.filik.eu/realms/filikrealm/account" target="_blank" rel="noreferrer" className="btn btn-primary p-2 m-2" >🆔 {t('user_management')}</a>
          <a href="https://status.filik.eu/status/public" target="_blank" rel="noreferrer" className="btn btn-primary p-2 m-2" role="button">🔋 {t('status_page')}</a>
        </div>
      </div>

      <div className="container px-4 py-5">
        <h2 className="pb-2 border-bottom">{t('available_apps')}</h2>
        <p>{t('available_apps_description')}</p>

        <AppList></AppList>
      </div>
    </div>
  )
}