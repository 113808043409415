'use strict';

import * as React from 'react'
import {AppEntry} from './appEntry'
import { AppMeta } from './appMeta';

const apps: Array<AppMeta> = [
  {
    name: 'cloud_storage',
    icon: 'nextcloud.svg',
    address: 'https://cloud.filik.eu',
    supportSSO: true
  },
  {
    name: 'chat_calls',
    icon: 'synapse.svg',
    address: 'https://element.filik.eu',
    supportSSO: true
  },
  {
    name: 'social',
    icon: 'mastodon.svg',
    address: 'https://social.filik.eu',
    supportSSO: true
  },
  /*{
    name: 'paste',
    icon: 'drift.svg',
    address: 'https://paste.filik.eu',
    supportSSO: true
  },
  {
    name: 'pixelfed',
    icon: 'pixelfed.svg',
    address: 'https://pixelfed.filik.eu',
    supportSSO: false
  },*/
  /*{
    name: 'blogging',
    icon: 'pencil.svg',
    address: 'https://pencil.filik.eu',
    supportSSO: true
  },*/
  /*{
    name: 'account',
    icon: 'keycloak.svg',
    address: '',
    supportSSO: true
  },*/
  /*{
    name: 'genealogy',
    icon: 'genea.png',
    address: 'https://genea.filik.eu',
    supportSSO: false
  }*/
]

export function AppList(): React.ReactElement {
  return (
    <div className="row text-center row-cols-1 row-cols-lg-3 g-4">
      {apps.map((app) => <AppEntry key={app.name} meta={app}></AppEntry>)}
    </div>
  )
}
